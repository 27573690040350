















import { Component, Vue, Prop } from 'vue-property-decorator'
import * as model from '../../models'
import { utils } from '@/utils/utils'
import {time_utils} from '@/utils/time_utils'
import enterCheck from '@/modules/studio/pages/detail/enterCheck'

@Component({

})
export default class LiveCard extends Vue {
  @Prop({ default: {} }) info!: model.LiveDecorate

  stateTxt: Array<any> = [
    {key: '未开始', style: 'no_start'},
    {key: '直播中', style: 'liveing'},
    {key: '看回放', style: 'live_end'}
  ]

  mounted() {
  }

  get getImgSrc() {
    return utils.createImgSrc(this.info.indexpic || this.info.live_indexpic, { width: 560 })
  }

  get getInfoTitle() {
    const info = this.info
    return `${time_utils.formatDate(info.start_timestamp)}开播`
  }

  get getInfoPrice() {
    const info = this.info
    let price = ''
    if (info.content_type === 'class_live') {
      price = '需订阅课程'
    } else {
      if (info.price && parseFloat(info.price) > 0) {
        price = `￥${utils.retainDigits(info.price.toString())}`
      } else {
        price = '免费'
      }
    }
    return price
  }

  get shopShowData() {
    return this.$store.state.shopShowData
  }
  
  get isShowPrice() {
    let price: number = Number(this.info.price)
    if (price) {
      return this.shopShowData.price
    } else {
      return this.shopShowData.free
    }
  }

  toDetail() {
    const type = this.info.content_type
    switch (type) {
      case 'content_live':
        this.$router.push({
          name: 'content',
          params: {
            type: 'live',
            id: this.info.content_id
          }
        })
        break
      case 'class_live':
        if (enterCheck(this.info, this, 'course')) {
          this.$router.push({
            name: 'courseStudio',
            params: {
              id: '' + this.info.course_class_id,
              courseId: this.info.course_id
            },
            query: {
              classContentId: this.info.content_id
            }
          })
        }
        break
    }
  }
}
