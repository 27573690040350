



















import { Component, Vue, Prop } from 'vue-property-decorator'
import * as model from '../../models'
import { utils } from '@/utils/utils'
import memberFreeTag from '@/modules/common/components/member-free-tag.vue'

@Component({
  components: {
    memberFreeTag
  }
})
export default class ContentCard extends Vue {
  @Prop({ default: {} }) info!: model.ContentDecorate
  @Prop({ default: false }) showType!: boolean

  get getImgSrc() {
    return utils.createImgSrc(this.info.cover_image, {width: 560})
  }
  get getInfoPrice() {
    const info = this.info
    let price = ''
    if ((info.lowest_price + info.highest_price) > 0) {
      let lowest = `${utils.retainDigits((info.lowest_price / 100).toString())}`
      let highest = `${utils.retainDigits((info.highest_price / 100).toString())}`
      if (lowest !== highest) {
        price = `￥${lowest} -￥${highest}`
      } else {
        price = `￥${lowest}`
      }
    } else {
      price = '免费'
    }
    return price
  }

  get originalPrice() {
    const info = this.info
    let price = ''
    if ((info.lowest_price + info.highest_price) > 0) {
      let lowest = `${utils.retainDigits((info.lowest_original_price / 100).toString())}`
      let highest = `${utils.retainDigits((info.highest_original_price / 100).toString())}`
      if (lowest !== highest) {
        price = `￥${lowest} -￥${highest}`
      } else {
        price = `￥${lowest}`
      }
    }
    return price
  }

  get shopShowData() {
    return this.$store.state.shopShowData
  }

  get isShowPrice() {
    let price: number = +this.info.highest_price
    if (price) {
      return this.shopShowData.price
    } else {
      return this.shopShowData.free
    }
  }

  toDetail() {
    this.$router.push({ name: 'OfflineDetail', params: { id: this.info.id } })
  }
}
