






















import { Component, Vue } from 'vue-property-decorator'
import ComponentNav from '../components/component-nav.vue'
import classifyCarousel from '@/modules/common/components/classify-carousel.vue'
import componentItem from '@/modules/common/components/component-item.vue'
import * as api from '../api'
import { storage, utils } from '@/utils/utils'
import * as model from '../models'

interface NavModel {
  title?: string
  brief?: string
  index_pic?: string
  state?: number
}

interface loadMore {
  page: number
  count: number
  more: boolean
}

@Component({
  components: {
    ComponentNav,
    classifyCarousel,
    componentItem
  }
})
export default class Home extends Vue {
  isEmpty: boolean = false
  firstBlankData: boolean = false
  isLoading: boolean = true
  navs: Array<NavModel> = []
  configIds: Array<string> = []
  configComponents: Array<model.DecorateConfigComponent> = []
  supportConfigComponents: Array<model.DecorateConfigComponent> = []
  decorateData: object = {}
  partDecorateConfigs: Array<model.DecorateConfigComponent> = []
  leftDecorateConfigs: Array<model.DecorateConfigComponent> = []
  supportSlugLists: Array<String> = [
    'column_bigpic',
    'column',
    'article',
    'article_bigpic',
    'article_compact',
    'audio',
    'audio_bigpic',
    'audio_compact',
    'video',
    'video_bigpic',
    'video_compact',
    'live_list',
    'live_bigpic',
    'course',
    'course_bigpic',
    'course_real_bigpic',
    'pintuan',
    'limited_discount',
    'latest_content',
    'latest_content_bigpic',
    'latest_content_compact',
    'hottest_content',
    'hottest_content_bigpic',
    'hottest_content_compact',
    'best_selling_content',
    'best_selling_content_bigpic',
    'best_selling_content_compact',
    'offline_course_list',
    'offline_course']
  loadMore: loadMore = {
    page: 1,
    count: 20,
    more: false
  }
  created() {

  }
  mounted() {
    this.renderDecorateData().catch(res => {
      if (!res || !res.response || res.response.status !== 401) {
        this.$message('店铺装修功能出错啦，请刷新重试~')
      }
      // 渲染店铺装修数据 = error ：错误机制
      console.error('renderDecorateData = ', res)
    })
    this.getNavigationList()
  }
  get shopInfo() {
    return this.$store.state.shopInfo
  }
  getNavigationList() {
    let params: object = {
      'type': 'web'
    }
    api.getNavigationList(params).then(
      res => {
        this.navs = res.data.filter((item: any) => item.state == 1)
      }
    ).catch(e => {
      console.log('e: ', e)
    })
  }

  isShowComponent(slug: string) {
    return this.supportSlugLists.indexOf(slug) > -1
  }

  // 渲染店铺装修
  async renderDecorateData() {
    let configComponents = await this.getConfigComponents()
    this.supportConfigComponents = this.getSupportedConfigComponents(configComponents)
    let decorateData = await this.loadDecorateData()
    this.checkConfigVersionUpdate()
  }

  // 加载店铺装修数据
  async loadDecorateData() {
    let configComponents = this.getCountDecorateConfigs()
    let configIds = this.getDecorateConfigIds(configComponents)
    if (!configIds.length) {
      this.isEmpty = true
      this.isLoading = false
      return
    }
    return api.getComponentList({
      component_ids: configIds.join(','),
      limits: 8
    }).then((data) => {
      this.decorateData = Object.assign({}, data, this.supportConfigComponents)
      if (this.loadMore.more) {
        if (this.isEmptyData(this.decorateData)) {
          this.isEmpty = false
          this.isLoading = true
        } else {
          this.isEmpty = false
          this.isLoading = false
        }
        this.loadMore.page++
        this.loadDecorateData()
      } else {
        if (this.isEmptyData(this.decorateData)) {
          this.isEmpty = true
          this.isLoading = false
          this.$message('店铺装修暂无新内容~')
        } else {
          this.isEmpty = false
          this.isLoading = false
        }
        return this.decorateData
      }
    }).catch(res => {
      console.error('loadDecorateData = ', res)
      return Promise.reject(res)
    })
  }

  // 获取店铺装修组件配置
  async getConfigComponents() {
    let configVersion = storage.getStorage('decorateVersion')
    let configComponents = storage.getStorage('decorateConfigList')
    if (configVersion && configComponents) {
      return configComponents
    } else {
      // 无版本号
      return this.requestDecorateConfig().then(data => {
        return data.components
      }).catch((res?: any) => {
        return Promise.reject(res)
      })
    }
  }

  // 检测店铺装修配置是否更新
  checkConfigVersionUpdate() {
    // 从缓存获取装修配置
    let configVersion = storage.getStorage('decorateVersion')
    if (configVersion) {
      // 带版本号
      this.requestDecorateConfig(configVersion).then(data => {
        if (data && data.components) {
          this.renderConfigVersionUpdate().catch(res => {
            console.error('renderConfigVersionUpdate =', res)
          })
          return data.components
        } else {
          return new Promise((resolve, reject) => {})
        }
      }).catch((res?: any) => {
        return new Promise((resolve, reject) => {})
      })
    }
  }

  // 更新店铺装修数据
  async renderConfigVersionUpdate() {
    let configComponents = await this.getConfigComponents()
    this.supportConfigComponents = this.getSupportedConfigComponents(configComponents)
    let decorateData = await this.loadDecorateData()
  }

  // 获取店铺装修配置
  async requestDecorateConfig(version?: string): Promise<model.DecorateConfig> {
    let p = {
      params: version ? { version } : {}
    }
    return api.getDecorateConfig(p).then((res): model.DecorateConfig | any => {
      // 有最新配置
      let data: any = res
      if (res.data) {
        // console.log('getOSSDecorateConfig success', res.data)
        data = res.data.decorate_config
      }
      if (data && data.components && data.version) {
        // 更新缓存 => 组件配置版本号 、组件配置文件
        storage.setStorage('decorateVersion', data.version)
        storage.setStorage('decorateConfigList', data.components)
        return data
      } else {
        return {}
      }
    }).catch((res) => {
      console.error('api_getDecorateConfig =', res)
      return Promise.reject(new Error('api_getDecorateConfig'))
    })
  }

  // 获取分页装修配置
  getCountDecorateConfigs() {
    let configComponents = this.supportConfigComponents
    let configs
    let loadMore = this.loadMore
    if (loadMore.more) {
      if (configComponents.length <= loadMore.page * loadMore.count) {
        loadMore.more = false
      }
      configs = configComponents.slice(loadMore.count * (loadMore.page - 1), loadMore.more ? loadMore.page * loadMore.count : configComponents.length)

    } else {
      if (configComponents.length > (loadMore.page * loadMore.count)) {
        loadMore.more = true
      }
      configs = configComponents.slice(loadMore.count * (loadMore.page - 1), loadMore.count)
    }
    return configs
  }

  // 获取店铺装修配置组件id数组
  getDecorateConfigIds(decorateConfig: Array<model.DecorateConfigComponent>) {
    if (decorateConfig.length === 0) {
      return []
    }
    let configIds:Array<string> = []
    decorateConfig.map((m, n) => {
      configIds.push(m.id)
    })
    return configIds
  }

  getSupportedConfigComponents(configComponents: Array<model.DecorateConfigComponent>) {
    let supportConfigs: Array<model.DecorateConfigComponent> = []
    configComponents.map((m,n) => {
      if (this.supportSlugLists.indexOf(m.component) > -1) {
        supportConfigs.push(m)
      }
    })
    return supportConfigs
  }

  isEmptyData(decorateData: { [key: string]: any }) {
    if (utils.isBlank(decorateData)) {
      return true;
    }

    for (const key in decorateData) {
      if (decorateData.hasOwnProperty(key)) {
        const element = decorateData[key];
        if (!utils.isBlank(element)) {
          return false;
        }
      }
    }
    return true;
  }
}
