












import { Component, Vue, Prop } from 'vue-property-decorator'
import { linkTo } from '@/utils/utils.ts';
  
@Component({
})
export default class ComponentNav extends Vue {
  @Prop({default: []}) navs!: Array<object>
  toLink(link: any) {
    linkTo(this.$router, link, undefined)
  }
}
