





















import { Component, Vue, Prop } from 'vue-property-decorator'
import * as model from '../../models'
import { utils } from '@/utils/utils'
import memberFreeTag from '@/modules/common/components/member-free-tag.vue'

@Component({
  components: {
    memberFreeTag
  }
})
export default class ContentCard extends Vue {
  @Prop({ default: {} }) info!: model.ContentDecorate
  @Prop({ default: false }) showType!: boolean

  get getImgSrc() {
    return utils.createImgSrc(this.info.indexpic, {width: 560})
  }
  get getInfoTitle() {
    const info = this.info
    let updataTime = info.update_time
    let sub = ''
    if (this.shopShowData.sub) {
      // sub = `·${utils.numberShowRules1(info.subscribe)}人在学`
      sub = `·${utils.numberShowRules1(info.view_count)}人在看`
    }
    return updataTime + sub
  }
  get getInfoPrice() {
    const info = this.info
    let price = ''
    if(info.subscribe_way === 3 || info.subscribeWay === 3) {
      price = '密码加入'
    } else {
      if (parseFloat(info.price) > 0) {
        price = `￥${utils.retainDigits(info.price.toString())}`
      } else {
        price = '免费'
      }
    }
    return price
  }

  get shopShowData() {
    return this.$store.state.shopShowData
  }

  get isShowPrice() {
    let price: number = +this.info.price
    if (price) {
      return this.shopShowData.price
    } else {
      return this.shopShowData.free
    }
  }

  toDetail() {  
    this.$router.push({ 
      name: 'columnDetail', 
      params: { 
        id: this.info.column_id
      } })
  }
  getYuanPriceUnit(val: any) {
    return utils.getYuanPriceUnit(val)
  }
}
