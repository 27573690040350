
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Carousel, CarouselItem } from 'element-ui'
import { getNavigationLists } from '@/modules/category/api'
import { CourseInfo } from '@/modules/course/models'
import { CateGory } from '@/modules/category/models'
import * as model from '../models'
import { linkTo } from '@/utils/utils.ts';
import * as api from '../api'

@Component({
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  }
})
export default class classifyCarousel extends Vue {
  classifyList: Array<CateGory> = []
  carouselList: Array<any> = []
  hasCate: boolean = false
  created() {

  }
  mounted() {
    this.getNavigationLists()
    this.getCarouselList()
    this.getPcSchoolDetail()
  }
  getPcSchoolDetail() {
    api.getPcSchoolDetail({}).then(
      (res: any) => {
        this.hasCate = res.displayed_category
      }
    ).catch(e => {
      console.log('e: ', e)
    })
  }
  getIsCursor(item: CateGory) {
    if (!item.nodes || !item.nodes.length) {
      return {
        cursor: true
      }
    }
  }
  toLink(link: any) {
    linkTo(this.$router, link, undefined)
  }
  getNavigationLists() {
    getNavigationLists({
      'only_leaf': false
    }).then(res => {
      this.classifyList = res.slice(0, 5)
    }).catch(e => {
      console.error('getNavigationLists = ', e)
    })
  }
  getCarouselList() {
    let params: object = {
      'type': 'web'
    }
    api.getCarouselList(params).then((res: any) => {
      this.carouselList = res.data.filter((item: any) => item.state == 1)
      if(this.carouselList.length == 0) {
        this.carouselList.push({ indexpic: require('@/assets/common/default-banner.jpg'), link: '' })
      }
    }).catch(e => {
      console.log('e: ', e)
    })
  }
  toCategoryList() {
    this.$router.push({ name: 'categoryList' })
  }
  toContentList(item: CateGory) {
    if (!item.nodes || !item.nodes.length) {
      this.$router.push({
        name: 'categoryContentList',
        params: {
          category_id: item.id
        }
      })
    }
  }
}
