



















import { Component, Vue, Prop } from 'vue-property-decorator'
import courseList from '@/modules/common/components/decorate/course.vue'
import contentList from '@/modules/common/components/decorate/content.vue'
import pintuanList from '@/modules/common/components/decorate/pintuan.vue'
import limitedList from '@/modules/common/components/decorate/limited.vue'
import liveList from '@/modules/common/components/decorate/live.vue'
import offlineList from '@/modules/common/components/decorate/offlinecourse.vue'
import columnList from '@/modules/common/components/decorate/colunm.vue'
import * as model from '@/modules/common/models'

@Component({
  components: {
    courseList,
    pintuanList,
    limitedList,
    liveList,
    contentList,
    offlineList,
    columnList
  }
})
export default class HomeComponentItem extends Vue {
  @Prop({ default: () => { return [] } }) compData!: Array<object>
  @Prop({ default: '' }) compConfig!: model.DecorateConfigComponent

  compTitle: string = ''
  compType: string = ''
  compSlugType: { [key: string]: string | object } = {
    'column_bigpic': 'column-list',
    'column': 'column-list',
    'article': 'content-list',
    'article_bigpic': 'content-list',
    'article_compact': 'content-list',
    'audio': 'content-list',
    'audio_bigpic': 'content-list',
    'audio_compact': 'content-list',
    'video': 'content-list',
    'video_bigpic': 'content-list',
    'video_compact': 'content-list',
    'live_list': 'live-list',
    'live_bigpic': 'live-list',
    'course': 'course-list',
    'course_bigpic': 'course-list',
    'course_real_bigpic': 'course-list',
    'latest_content': 'content-list',
    'latest_content_bigpic': 'content-list',
    'latest_content_compact': 'content-list',
    'hottest_content': 'content-list',
    'hottest_content_bigpic': 'content-list',
    'hottest_content_compact': 'content-list',
    'best_selling_content': 'content-list',
    'best_selling_content_bigpic': 'content-list',
    'best_selling_content_compact': 'content-list',
    'pintuan': 'pintuan-list',
    'limited_discount': 'limited-list',
    'offline_course_list': 'offline-list',
    'offline_course': 'offline-list'
  }
  groupTypes: Array<string> = [
    'latest_content',
    'latest_content_bigpic',
    'latest_content_compact',
    'hottest_content',
    'hottest_content_bigpic',
    'hottest_content_compact',
    'best_selling_content',
    'best_selling_content_bigpic',
    'best_selling_content_compact',
    'pintuan',
    'limited_discount',
    'offline_course_list',
    'offline_course']

  created() {
    this.compTitle = this.compConfig.data_mapping.name
    this.compType = this.compConfig.component
  }
  get getCompType() {
    return this.compSlugType[this.compType]
  }
  get getIsShowType() {
    if (this.groupTypes.includes(this.compType)) {
      return true
    } else {
      return false
    }
  }
  toList() {
    const type = this.compType
    let routerName = ''
    let params: { [key: string]: string } = {}
    let query: { [key: string]: string } = {}
    switch (type) {
      case 'column_bigpic':
      case 'column':
      case 'article':
      case 'article_bigpic':
      case 'article_compact':
      case 'audio':
      case 'audio_bigpic':
      case 'audio_compact':
      case 'video':
      case 'video_bigpic':
      case 'video_compact':
      case 'latest_content':
      case 'latest_content_bigpic':
      case 'latest_content_compact':
      case 'hottest_content':
      case 'hottest_content_bigpic':
      case 'hottest_content_compact':
      case 'best_selling_content':
      case 'best_selling_content_bigpic':
      case 'best_selling_content_compact':
        routerName = 'contentList'
        params['type'] = type.replace(/_bigpic|_compact/, '')
        query['componentId'] = this.compConfig.id || ''
        query['title'] = this.compTitle
        break;
      case 'live_list':
      case 'live_bigpic':
        routerName = 'contentList'
        params['type'] = 'live'
        query['componentId'] = this.compConfig.id || ''
        query['title'] = this.compTitle
        break
      case 'course':
      case 'course_bigpic':
      case 'course_real_bigpic':
        routerName = 'courseList'
        query['title'] = this.compTitle
        break;
      case 'pintuan':
        routerName = 'PintuanList'
        query['title'] = this.compTitle
        break;
      case 'limited_discount':
        routerName = 'LimitList'
        query['title'] = this.compTitle
        break;
      case 'offline_course_list':
      case 'offline_course':
        routerName = 'OfflineList'
        query['title'] = this.compTitle
        break
      default:
        break;
    }
    this.$router.push({name: routerName, params, query})
  }
}
