

























import { Component, Vue, Prop } from 'vue-property-decorator'
import * as model from '../../models'
import { utils } from '@/utils/utils'
import {time_utils} from '@/utils/time_utils'
import sweepCodeDialog from '@/modules/common/components/sweep-code-dialog.vue'
import {isSupportType} from '@/config/typeConfig'

@Component({
  components: {
    sweepCodeDialog
  }
})
export default class ContentCard extends Vue {
  @Prop({ default: {} }) info!: model.ContentDecorate
  @Prop({ default: '' }) slug!: string

  status: number = 0
  activityCountDown: any = null
  activityTimer: any = {
    day: '00',
    hour: '00',
    min: '00',
    sec: '00'
  }

  dialogOption: object = {
    showDialog: false,
    type: '',
    contentId: '',
    title: '请前往h5店铺支付，支付完成后刷新页面即可浏览'
  }

  mounted() {
    this.doCountdown()
  }

  beforeDestory() {
    clearInterval(this.activityCountDown)
  }

  get getImgSrc() {
    let pic = this.info.indexpic
    return utils.createImgSrc(pic, {width: 560})
  }
  get shopShowData() {
    return this.$store.state.shopShowData
  }
  get isShowPrice() {
    return true // 先与H5同步,  营销活动要显示价格
    // let price: number = +this.info.limit_price
    // if (price) {
    //   return this.shopShowData.price
    // } else {
    //   return this.shopShowData.free
    // }
  }
  get getInfoPrice() {
    const info = this.info
    let price = {
      cur: `${info.limit_price}`,
      origin: `${info.cost_price}`
    }
    return price
  }
  get timerText() {
    let {day, hour, min, sec} = this.activityTimer
    let t = `${day}天${hour}:${min}:${sec}`
    if (this.status === 0) {
      return `还有${t}开始`
    } else {
      return `距结束 ${t}`
    }
  }

  toDetail() {
    if (!isSupportType(this.info.type)) {
      this.dialogOption = {
        showDialog: true,
        type: this.info.product_category,
        contentId: this.info.product_identifier,
        title: '更多精彩内容请访问移动端店铺'
      }
      return
    }
    if (this.info.type === 'course') {
      this.$router.push({
        name: 'course',
        params: {
          id: this.info.content_id
        }
      })
    } else if (this.info.type === 'offlinecourse') {
      this.$router.push({
        name: 'OfflineDetail',
        params: {
          id: this.info.content_id
        }
      })
    } else {
      this.$router.push({
        name: 'content',
        params: {
          type: this.info.type,
          id: this.info.content_id
        }
      })
    }
  }

  formatDate(s: string) {
    return s.replace(/-/g, '/')
  }

  doCountdown() {
    let startTime = +new Date(this.formatDate(this.info.limit_purshase.start_time))
    let endTime = +new Date(this.formatDate(this.info.limit_purshase.end_time))
    let nowTime = new Date().getTime()

    if (nowTime - endTime > 0) {
      this.status = -1
      return
    }
    // 未开始
    if (nowTime - startTime < 0) {
      this.status = 0
      this.activityCountDown = setInterval(() => {
        nowTime = new Date().getTime()
        if (nowTime - startTime < 0) {
          this.activityTimer = time_utils.counterTime(startTime)
        } else {
          this.status = 1
          this.doCountdown()
        }
      }, 1000)
    } else if (nowTime - endTime <= 0) {  // 进行中
      this.status = 1
      this.activityCountDown = setInterval(() => {
        nowTime = new Date().getTime()
        if (nowTime - endTime < 0) {
          this.activityTimer = time_utils.counterTime(endTime)
        } else {
          this.status = -1
          clearInterval(this.activityCountDown)
        }
      }, 1000)
    }
  }
}
